export default function Footer() {
  return (
    <footer className="p-4 py-6 bg-custom-darkRed">
      <p className="text-base text-white text-center font-medium">
        Copyright © 2024 | Todos os direitos reservados. Aplicativo Oficial do
        truque da cerveja.
      </p>
    </footer>
  );
}
