import profile1 from "../../assets/profiles/profile-1.jpeg";
import profile2 from "../../assets/profiles/profile-2.jpeg";
import profile3 from "../../assets/profiles/profile-3.jpeg";
import profile4 from "../../assets/profiles/profile-4.jpeg";
import profile5 from "../../assets/profiles/profile-5.jpeg";
import profile6 from "../../assets/profiles/profile-6.jpeg";
import love from "../../assets/love.png";
import like from "../../assets/like.png";

export default function Comments() {
  const commentsData = getCommentsData();

  const commentsElements = commentsData.map((commentData) => {
    return (
      <li key={commentData.id}>
        <CommentContainer
          key={commentData.id}
          name={commentData.name}
          comment={commentData.comment}
          reactions={commentData.reactions}
          profileImage={commentData.profileImage}
          hasReplay={commentData.hasReplay}
          replays={commentData.replays}
          id={commentData.id}
          postedAt={commentData.postedAt}
        />
      </li>
    );
  });

  return (
    <div className="mt-6">
      <h2 className="font-bold text-custom-black-3 text-3xl md:text-5xl text-center">
        24.764 comentários
      </h2>

      <article className="flex flex-col gap-3 mt-10 py-10 border-t border-custom-black">
        <h2 className="text-sm font-semibold">Mostrando 6 comentários</h2>

        <ul className="flex flex-col gap-2 overflow-auto">
          {commentsElements}
        </ul>

        <h3 className="text-center font-semibold text-[#555555] text-sm">
          Você precisa estar logado para comentar
        </h3>
      </article>
    </div>
  );
}

function CommentContainer(props) {
  const replaysElements = props.replays.map((replayData) => {
    return (
      <li key={replayData.id}>
        <Comment
          key={replayData.id}
          name={replayData.name}
          comment={replayData.comment}
          reactions={replayData.reactions}
          profileImage={replayData.profileImage}
          postedAt={replayData.postedAt}
        />
      </li>
    );
  });

  return (
    <article className="flex flex-col gap-2">
      <Comment
        key={props.id}
        name={props.name}
        comment={props.comment}
        reactions={props.reactions}
        profileImage={props.profileImage}
        postedAt={props.postedAt}
      />

      {props.hasReplay && (
        <ul className="flex flex-col gap-2 ml-12 pl-2 border-l-2 border-dotted border-stone-100">
          {replaysElements}
        </ul>
      )}
    </article>
  );
}

function Comment(props) {
  return (
    <article className="flex gap-1 w-full">
      <img
        className="grow w-11 h-11 rounded-full shrink-0 cursor-pointer object-cover"
        src={props.profileImage}
        alt="Foto de perfil do facebook."
      />

      <div className="flex flex-col w-full">
        <article className="p-1 px-4 bg-stone-100 rounded-[18px] text-sm">
          <h2 className="font-semibold text-custom-blue cursor-pointer w-fit hover:underline">
            {props.name}
          </h2>

          <p className="font-base text-custom-black">{props.comment}</p>
        </article>

        <div className="flex justify-between gap-2">
          <ul className="flex items-center gap-1 text-sm text-custom-gray ml-4">
            <li>
              <button type="button" className="text-nowrap hover:underline">
                Curtir
              </button>
            </li>
            <li>
              <span>&middot;</span>
            </li>
            <li>
              <button type="button" className="text-nowrap hover:underline">
                Responder
              </button>
            </li>
            <li>
              <span>&middot;</span>
            </li>
            <li>
              <button type="button" className="text-nowrap hover:underline">
                {props.postedAt} min
              </button>
            </li>
          </ul>

          <div className="bg-white shadow-lg w-fit relative -top-2 right-3 rounded-2xl px-1 shrink-0 h-fit">
            <ul className="flex gap-1 items-center w-fit">
              <li>
                <img className="w-4 h-4 shrink-0" src={like} alt="" />
              </li>
              <li>
                <img className="w-4 h-4 shrink-0" src={love} alt="" />
              </li>
              <li>
                <mark className="bg-transparent text-sm font-normal text-custom-gray">
                  {props.reactions}
                </mark>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
}

function getCommentsData() {
  return [
    {
      id: 1,
      name: "Adailton Junior",
      comment: "Será que esse truque funciona mesmo?",
      reactions: 59,
      profileImage: profile1,
      hasReplay: true,
      replays: [
        {
          id: 20,
          name: "José marques",
          comment:
            "Rapaz, funciona!!! Eu pensei que seria mais um desses golpes na internet, mas eu estou fazendo e já emagreci 9kg e a minha pochete secou. Vale muito a pena.",
          reactions: 77,
          profileImage: profile4,
          hasReplay: false,
          replays: [],
          postedAt: 7,
        },
      ],
      postedAt: 1,
    },
    {
      id: 2,
      name: "Felipe Mota",
      comment:
        "Esse truque da cerveja mudou o rumo das coisas aqui em casa, minha esposa gostou bastante! Perdi 24kg e minha barriga sumiu completamente, sem fazer academia ou parar de comer lasanha",
      reactions: 118,
      profileImage: profile2,
      hasReplay: false,
      replays: [],
      postedAt: 4,
    },
    {
      id: 3,
      name: "João Batista",
      comment:
        "O Brasil tá precisando disso, sem enrolação e direto ao ponto! Já tô fazendo esse truque da cerveja tem alguns dias e perdi quase 8kg de pura barriga",
      reactions: 67,
      profileImage: profile3,
      hasReplay: false,
      replays: [],
      postedAt: 10,
    },
    {
      id: 6,
      name: "Roberto Chagas",
      comment:
        "Vou ser sincero, eu odiava tirar a camisa na praia e ver minha barriga grande e dura pulando pra fora… Até conhecer esse truque da cerveja, que pode ser feito com qualquer cervejinha beeem gelada, e conseguir emagrecer mais de 16 quilos de barriga.",
      reactions: 25,
      profileImage: profile5,
      hasReplay: false,
      replays: [],
      postedAt: 13,
    },
    {
      id: 7,
      name: "Agnaldo Oliveira",
      comment:
        "Não troco isso por nada!!!! Eu não achei que esse truque da cerveja não iria fazer efeito, mas mesmo assim decidi fazer e funcionou. Eu perdi mais de 10kg de barriga em 19 dias.",
      reactions: 51,
      profileImage: profile6,
      hasReplay: false,
      replays: [],
      postedAt: 14,
    },
  ];
}
