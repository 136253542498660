import { useEffect } from "react";

export default function Video() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://scripts.converteai.net/95571ba8-98d1-4a43-9477-8adefd091c5e/ab-test/661de9b1163fab00084a6d4c/player.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <article className="w-full mt-6">
      <div id="scr_661de9b1163fab00084a6d4c"></div>
    </article>
  );
}
